import Vue from 'vue'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { getDateTimeRange, getChildrenFromList, selectorDateTimeValidation } from '@/tools/functions'
import i18n from '@/i18n'
import thingApi from '@/api/thing.api'
import driveUnitApi from '@/api/drive-unit.api'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import CdpReportDetail from '@/components/cdp-report/detail/CdpReportDetail.vue'
import { SnackbarStyle } from '@/constants/constants'
import cdpReportService from '@/business/cdpReportService'
import cdpReportConstants from '@/constants/cdpReport.constants'

export default {
  name: 'CdpReport',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
    CdpReportDetail
  },
  model: {
    prop: 'model'
  },
  /**
   * MODELO
   *
   * sector: String. La clave del sector al que corresponde el reporte, para prefiltrar actividades y tipos de cosas
   *  --> Ejemplo: "FIELD_ENGINEERING"
   */
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    loadingTable: false,
    showSelector: false,
    selectorModel: {
      showClose: true,
      filters: [],
      selects: [],
      disableGetReportButton: true,
      showDateAndTimeSelector: true,
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: false,
      autoReport: false,
      autoReportMinutes: 10
    },
    // fltros
    harvesterFilter: {
      id: 'harvesters',
      name: i18n.t('selectorTitles.harvesters'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    planterFilter: {
      id: 'planters',
      name: i18n.t('selectorTitles.planters'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    originDriveUnitFilter: {
      id: 'originDU',
      name: i18n.t('selectorTitles.originDU'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    destinationDriveUnitFilter: {
      id: 'destinationDU',
      name: i18n.t('selectorTitles.destinationDU'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    // backup de selectores
    planterIds: [],
    harvesterIds: [],
    destinationDriveUnits: [],
    originDriveUnits: [],
    dateAndTimeRange: null,
    dateAndTimeRangeCustomType: null,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    timeStampFrom: null,
    timeStampTo: null,
    autoReport: false,
    autoReportMinutes: 10,
    // ------------------------
    settingDisabled: false,
    tabsCdp: [
      {
        id: 'tab-cdp-summary',
        name: i18n.t('dashboard.cdp')
      }
    ],
    tabSelectedCdp: 'tab-cdp-summary',
    breadcrumbButtomsCdp: [],
    loadingSpinner: true,
    // modelo del componente del detalle
    reportDetailModel: {
      loadingSpinner: false,
      reportKey: null,
      autoReport: false,
      autoReportMinutes: null
    },
    intervalId: null,
    disableRefreshTable: true,
    // unidades de manejo destino que luego se cargarán en el selector del detalle
    destinationDriveUnitsForSelector: []
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
    this.breadcrumbButtomsCdp.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Inicializo los datos del selector
    await this.initializeSelector()
    // Verifico si hay una clave de reporte como parámetro y cargo ese reporte
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      // push de filtros al arreglo
      this.selectorModel.filters.push(this.harvesterFilter, this.planterFilter, this.originDriveUnitFilter, this.destinationDriveUnitFilter)

      const harvesters = await thingApi.getWorkFrontsByTypeKeys(['HARVESTER'], 'FARM')
      this.harvesterFilter.data.cleanAndUpdate(harvesters.data)
      const planters = await thingApi.getWorkFrontsByTypeKeys(['PLANTER'], 'FARM')
      this.planterFilter.data.cleanAndUpdate(planters.data)
      const driveUnits = await driveUnitApi.getStructureByEnterprise()
      const hasDriveUnits = this.enterpriseHasDriveUnits(driveUnits)
      if (hasDriveUnits) {
        this.originDriveUnitFilter.data.cleanAndUpdate(driveUnits.structure)
        this.destinationDriveUnitFilter.data.cleanAndUpdate(driveUnits.structure)
      } else {
        this.originDriveUnitFilter.data.cleanAndUpdate([])
        this.destinationDriveUnitFilter.data.cleanAndUpdate([])
        this.harvesterFilter.disabled = true
        this.planterFilter.disabled = true
        this.originDriveUnitFilter.disabled = true
        this.destinationDriveUnitFilter.disabled = true
        this.selectorModel.disableGetReportButton = true
        this.showSnackbar({ visible: true, text: this.$t('driveUnits.noData'), timeout: 8000, style: SnackbarStyle.ERROR })
      }
    },
    /**
     * Para el botón del breadcrumb
     */
    selector () {
      this.showSelector = !this.showSelector
    },
    /**
     * Validar que una empresa tenga unidades de manejos
     */
    enterpriseHasDriveUnits (driveUnits) {
      let hasDriveUnits = false
      if (driveUnits && driveUnits.structure) {
        const structureDriveUnits = []
        getChildrenFromList(driveUnits.structure, structureDriveUnits)
        if (structureDriveUnits.length > 0) {
          hasDriveUnits = true
        }
      }
      return hasDriveUnits
    },
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton () {
      return this.settingDisabled
    },
    /**
     * Acción del botón para actualizar los datos de la tabla
     */
    refreshTable () {
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      this.getDataNotDeferred(true)
    },
    getReport (eventData) {
      this.loadingTable = true
      // timestamps
      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      // backup de selectores de rango de tiempo
      this.dateAndTimeRange = eventData.dateAndTimeRange
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.sinceDate = eventData.sinceDate
      this.sinceTime = eventData.sinceTime
      this.toDate = eventData.toDate
      this.toTime = eventData.toTime
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      // cosechadoras
      const harvesters = []
      getChildrenFromList(this.harvesterFilter.selectedData, harvesters)
      this.harvesterIds.cleanAndUpdate(harvesters.map(element => element.id))
      // plantadoras
      const planters = []
      getChildrenFromList(this.planterFilter.selectedData, planters)
      this.planterIds.cleanAndUpdate(planters.map(element => element.id))
      // unidades de manejo origen
      const originDU = []
      getChildrenFromList(this.originDriveUnitFilter.selectedData, originDU)
      this.originDriveUnits.cleanAndUpdate(originDU.map(element => element.id))
      // unidades de manejo destino
      const destinationDU = []
      getChildrenFromList(this.destinationDriveUnitFilter.selectedData, destinationDU)
      this.destinationDriveUnits.cleanAndUpdate(destinationDU.map(element => element.id))
      // auto-reporte
      this.autoReport = eventData.autoReport
      this.autoReportMinutes = eventData.autoReportMinutes

      // model del componente del detalle
      this.reportDetailModel.autoReport = eventData.autoReport
      this.reportDetailModel.autoReportMinutes = eventData.autoReportMinutes

      if (eventData.autoReport) {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        this.getDataNotDeferred()
        clearInterval(this.intervalId)
        const that = this
        this.intervalId = setInterval(
          function () {
            that.getDataNotDeferred(true)
          }, eventData.autoReportMinutes * 60 * 1000)
      } else {
        const reportQueryFilters = {
          route: this.$route.path,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          planterIds: this.planterIds,
          harvesterIds: this.harvesterIds,
          originDriveUnits: this.originDriveUnits,
          destinationDriveUnits: this.destinationDriveUnits
        }
        const filters = {
          harvesters,
          planters,
          originDU,
          destinationDU,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          selectedDateAndTimeRange: this.dateAndTimeRange,
          selectedDateAndTimeRangeCustomType: eventData.dateAndTimeRangeCustomType,
          sinceDate: this.sinceDate,
          sinceTime: this.sinceTime,
          toDate: this.toDate,
          toTime: this.toTime
        }
        cdpReportService.getSummaryDeferred(reportQueryFilters, filters)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    /**
     * Para obtener los datos del reporte diferido
     * @param {*} reportKey
     */
    getData (reportKey) {
      if (reportKey) {
        this.settingDisabled = false
        this.showSelector = false
        this.disableRefreshTable = true
        this.loadingTable = true
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        reportStorageApi.getReport(reportKey).then(response => {
          // proceso los datos
          const { data, reportKey, filters } = response.data
          const processedSummary = cdpReportService.processSummary(data)

          this.setTableFilterRules(cdpReportConstants.SummaryHeaders)
          this.tableHeaders.cleanAndUpdate(cdpReportConstants.SummaryHeaders)
          this.tableData.cleanAndUpdate(processedSummary.data)
          this.destinationDriveUnitsForSelector.cleanAndUpdate(processedSummary.destinationDriveUnits)

          this.reportDetailModel.reportKey = reportKey

          if (this.loadingSpinner) this.commitVisible({ visible: false })
          this.setFilterData(filters)
          this.setReportDetails()
          this.loadingTable = false
        })
      } else {
        this.showSelector = true
      }
    },
    /**
     * Modo autorreporte, para obtener y actualizar los datos del reporte
     */
    getDataNotDeferred (refresh = false) {
      this.loadingTable = true
      cdpReportService.getSummaryDirect(
        this.planterIds, this.harvesterIds, this.originDriveUnits,
        this.destinationDriveUnits, this.timeStampFrom, this.timeStampTo).then(result => {
        if (!refresh) {
          this.setTableFilterRules(result.headers)
          this.tableHeaders.cleanAndUpdate(result.headers)
          this.setReportDetails()
          this.disableRefreshTable = false
        }

        this.tableData.cleanAndUpdate(result.data)
        this.destinationDriveUnitsForSelector.cleanAndUpdate(result.reportDestinationDriveUnits)
        this.reportDetailModel.reportKey = result.summaryReportKey

        this.loadingTable = false
        if (this.loadingSpinner) this.commitVisible({ visible: false })
      })
    },
    /**
     * Para configurar el detalle del reporte
     */
    setReportDetails () {
      const detailData = {
        from: this.timeStampFrom,
        to: this.timeStampTo
      }
      const dataStructure = [
        { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
        { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' }
      ]
      if (this.harvesterFilter.selectedData.length > 0) {
        const harvesters = []
        getChildrenFromList(this.harvesterFilter.selectedData, harvesters)
        detailData.harvesters = harvesters
        dataStructure.push({
          detailDataProp: 'harvesters',
          propTitle: this.$t('selectorTitles.harvesters'),
          propType: 'LIST',
          propRefs: { list: harvesters, value: 'id', text: 'name' } })
      } else if (this.planterFilter.selectedData.length > 0) {
        const planters = []
        getChildrenFromList(this.planterFilter.selectedData, planters)
        detailData.planters = planters
        dataStructure.push({
          detailDataProp: 'planters',
          propTitle: this.$t('selectorTitles.planters'),
          propType: 'LIST',
          propRefs: { list: planters, value: 'id', text: 'name' } })
      } else {
        if (this.originDriveUnitFilter.selectedData.length > 0) {
          const originDU = []
          getChildrenFromList(this.originDriveUnitFilter.selectedData, originDU)
          detailData.originDU = originDU
          dataStructure.push({
            detailDataProp: 'originDU',
            propTitle: this.$t('selectorTitles.originDU'),
            propType: 'LIST',
            propRefs: { list: originDU, value: 'id', text: 'name' } })
        }
        if (this.destinationDriveUnitFilter.selectedData.length > 0) {
          const destinationDU = []
          getChildrenFromList(this.destinationDriveUnitFilter.selectedData, destinationDU)
          detailData.destinationDU = destinationDU
          dataStructure.push({
            detailDataProp: 'destinationDU',
            propTitle: this.$t('selectorTitles.destinationDU'),
            propType: 'LIST',
            propRefs: { list: destinationDU, value: 'id', text: 'name' } })
        }
      }
      this.setDetails({
        detailData,
        dataStructure
      })
    },
    /**
     * Para setear las reglas de filtrado
     * @param {*} headers
     */
    setTableFilterRules (headers) {
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * Cambia de tab (breadcrumb)
     * @param {*} id
     */
    tabSelected (id) {
      const detailRef = this.$refs['cdp-report-detail']
      if (id === 'tab-cdp-summary') {
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = false
        this.loadingSpinner = true
      } else if (id === 'tab-cdp-detail' && detailRef) {
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = true
        this.loadingSpinner = false
      }
      this.tabSelectedCdp = id
    },
    /**
     * Clic en una fila de la tabla (ir al detalle)
     * Primero se carga el selector de dispositivos y luego se mueve al detalle
     * @param {*} data
     */
    summaryReportRowClick (data) {
      // se carga el detalle solo si la unidad de manejo destino es una unidad de manejo válida
      if (!data.unknownDestinationDriveUnit) {
        this.setEntityConfiguration({
          name: this.$t('selectorTitle.originDU'),
          value: 'id',
          text: 'name' })
        this.setEntitySelectorItems(this.destinationDriveUnitsForSelector)
        this.setSelectedItem({
          id: data.destinationDriveUnitId,
          name: data.destinationDriveUnitName
        })
        if (!this.tabsCdp.find(tab => tab.id === 'tab-cdp-detail')) {
          this.tabsCdp.push({
            id: 'tab-cdp-detail',
            name: i18n.t('detail')
          })
        }
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = true
        this.tabSelectedCdp = 'tab-cdp-detail'
      }
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const hasDriveUnits = that.originDriveUnitFilter.data.length > 0 || that.destinationDriveUnitFilter.data.length > 0
        const harvestersSelected = that.harvesterFilter.selectedData
        const plantersSelected = that.planterFilter.selectedData
        const originDUSelected = that.originDriveUnitFilter.selectedData
        const destinationDUSelected = that.destinationDriveUnitFilter.selectedData
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        that.selectorModel.disableGetReportButton =
          (harvestersSelected.length === 0 && originDUSelected.length === 0 && plantersSelected.length === 0 && destinationDUSelected.length === 0) ||
          customTimeValidation

        that.harvesterFilter.disabled = !hasDriveUnits || originDUSelected.length > 0 || plantersSelected.length > 0 || destinationDUSelected.length > 0
        that.planterFilter.disabled = !hasDriveUnits || originDUSelected.length > 0 || harvestersSelected.length > 0 || destinationDUSelected.length > 0
        that.originDriveUnitFilter.disabled = !hasDriveUnits || harvestersSelected.length > 0 || plantersSelected.length > 0
        that.destinationDriveUnitFilter.disabled = !hasDriveUnits || harvestersSelected.length > 0 || plantersSelected.length > 0
      })
    },
    setFilterData (filterData) {
      this.harvesterFilter.selectedData = filterData.harvesters || []
      this.destinationDriveUnitFilter.selectedData = filterData.destinationDU || []
      this.originDriveUnitFilter.selectedData = filterData.originDU || []
      this.planterFilter.selectedData = filterData.planters || []
      this.timeStampFrom = filterData.from
      this.timeStampTo = filterData.to
      this.selectorModel.selectedDateAndTimeRange = filterData.selectedDateAndTimeRange
      this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.selectedDateAndTimeRangeCustomType || CustomTimeRangeTypes.DATE
      this.selectorModel.sinceDate = filterData.sinceDate
      this.selectorModel.sinceTime = filterData.sinceTime
      this.selectorModel.toDate = filterData.toDate
      this.selectorModel.toTime = filterData.toTime
    }
  },
  watch: {
    'selectorModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.selectedDateAndTimeRange': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.selectedDateAndTimeRangeCustomType': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.sinceDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.sinceTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.toDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.toTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
