import service from '@/middleware'

const cdpReportApi = {
  // REPORTE DIFERIDO
  async getSummaryDeferred (reportQueryFilters, filters) {
    const result = await service.http.post('/api/cdp-report/deferred', {
      reportQueryFilters,
      filters
    })
    return result
  },
  // REPORTE NO DIFERIDO (AUTO-REPORTE)
  async getSummaryDirect (reportQueryFilters) {
    const result = await service.http.post('/api/cdp-report/direct', reportQueryFilters)
    return result
  },
  // DETALLE
  async getDetail (reportKey, destinationDriveUnitId) {
    const result = await service.http.post('/api/cdp-report/detail', {
      reportKey,
      destinationDriveUnitId
    })
    return result
  }
}

export default cdpReportApi
