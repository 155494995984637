import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapGetters } from 'vuex'
import cdpReportService from '@/business/cdpReportService'
import { dateSortFunction } from '@/tools/functions'

export default {
  name: 'CurrentStatusReportDetail',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    loadingTable: true,
    intervalId: null
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  beforeDestroy () {
    this.resetEntitySelector()
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if (this.selectedItem) {
      this.getData()
    }
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('reportDefer', {
      commitVisible: 'commitVisible',
      closeReportDefer: 'closeReportDefer'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    onMapClick (element) {
      console.log('map clicked', element)
    },
    refreshTable () {
      console.log('refresh table')
      if (this.selectedItem) {
        this.getData(true)
      }
    },
    resize () {
      console.log('resize')
    },
    async getData (refresh = false) {
      this.getDetail(refresh)
      if (this.model.autoReport) {
        const that = this
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            that.getDetail(true)
          }, that.model.autoReportMinutes * 60 * 1000)
      }
    },
    getDetail (refresh = false) {
      this.loadingTable = true
      if (this.model.loadingSpinner) this.commitVisible({ visible: true })
      cdpReportService.getDetail(this.model.reportKey, this.selectedItem.id).then(result => {
        if (!refresh) {
          this.setTableFilterRules(result.headers)
          this.setSortFunctions(result.headers)
          this.tableHeaders.cleanAndUpdate(result.headers)
        }
        this.tableData.cleanAndUpdate(result.data)
        this.loadingTable = false
        if (this.model.loadingSpinner) this.commitVisible({ visible: false })
      })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    setSortFunctions (headers) {
      /*
        Para ordenar las columnas fecha y hora
        TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      headers.find(header => header.value === 'tractorDischargeDate').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData()
      }
    }
  }
}
