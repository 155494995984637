/* eslint-disable indent */
import { FilterType, DataType, CellType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

/**
 * headers para la tabla del resumen
 */
const SummaryHeaders = [
    {
        text: i18n.t('cdpReport.Headers.destinationDriveUnitName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'destinationDriveUnitName'
    },
    {
        text: i18n.t('cdpReport.Headers.originDriveUnitName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originDriveUnitName'
    },
    {
        text: i18n.t('cdpReport.Headers.variety'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originDriveUnitVariety'
    },
    {
        text: i18n.t('cdpReport.Headers.harvestedArea'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originHarvestedHectares'
    },
    {
        text: i18n.t('cdpReport.Headers.plantedArea'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'plantedHectares'
    },
    {
        text: i18n.t('cdpReport.Headers.trips'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'trips'
    },
    {
        text: i18n.t('cdpReport.Headers.deviation'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'deviation',
        cellType: CellType.textColor,
        cellConfig: {
            color: 'deviationColor'
        }
    }
]

/**
 * headers para la tabla del detalle
 */
const DetailHeaders = [
    {
        text: i18n.t('cdpReport.Headers.farm'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'farm'
    },
    {
        text: i18n.t('cdpReport.Headers.destinationDriveUnitName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'destinationDriveUnitName'
    },
    {
        text: i18n.t('cdpReport.Headers.originDriveUnitName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originDriveUnitName'
    },
    {
        text: i18n.t('cdpReport.Headers.variety'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originDriveUnitVariety'
    },
    {
        text: i18n.t('cdpReport.Headers.frontName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'workFrontName'
    },
    {
        text: i18n.t('cdpReport.Headers.harvesterName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originHarvesterName'
    },
    {
        text: i18n.t('cdpReport.Headers.harvestedArea'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originHarvestedHectares'
    },
    {
        text: i18n.t('cdpReport.Headers.tractorName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'originTractorName'
    },
    {
        text: i18n.t('cdpReport.Headers.planterName'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'planterName'
    },
    {
        text: i18n.t('cdpReport.Headers.plantedArea'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantedHectares'
    },
    {
        text: i18n.t('cdpReport.Headers.plantingWidth'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'plantingWidth'
    },
    {
        text: i18n.t('cdpReport.Headers.deviation'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        cellType: CellType.textColor,
        cellConfig: {
            color: 'deviationColor'
        },
        value: 'deviation'
    },
    {
        text: i18n.t('cdpReport.Headers.tractorDischargeDate'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'tractorDischargeDate',
        width: 200
    }
]

/**
 * Color de la desviación
 * >= 6 => good
 * < 6 => bad
 */
const DeviationColor = {
    good: '#4CAF50',
    bad: '#f44336'
}

/**
 * Constantes para las unidades de manejo no identificadas
 */
const UNKNOWN_DRIVE_UNIT = 'UNKNOWN_DRIVE_UNIT'

export default {
    SummaryHeaders,
    DetailHeaders,
    DeviationColor,
    UNKNOWN_DRIVE_UNIT
}
