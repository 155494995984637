/* eslint-disable indent */
import { ReportConversionUtil, Util } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'
import cdpReportApi from '@/api/cdpReport.api'
import cdpReportConstants from '../constants/cdpReport.constants'
import { getTimeOffset } from '@/tools/functions'

/**
 * Obtener los datos del detalle
 * @param {*} reportKey
 * @param {*} destinationDriveUnitId
 * @returns
 */
const getDetail = async (reportKey, destinationDriveUnitId) => {
    let processedData
    try {
        const response = await cdpReportApi.getDetail(reportKey, destinationDriveUnitId)
        processedData = processDetail(response.data)
    } catch (exception) {
        console.error(exception)
    }
    return {
        data: processedData || [],
        headers: cdpReportConstants.DetailHeaders
    }
}

/**
 * Procesar los datos del detalle
 * @param {*} data
 * @returns
 */
const processDetail = (data) => {
    const timeOffset = getTimeOffset()

    data.forEach((element, index) => {
        // id para la tabla
        element.id = index

        // fecha y hora de la descarga
        if (element.tractorDischargeTimestamp != null) {
            const tractorDischargeDate = ReportConversionUtil.applyTimezone(element.tractorDischargeTimestamp, timeOffset)
            element.tractorDischargeDate = tractorDischargeDate.dateString + ' ' + tractorDischargeDate.timeString
        }

        // color de la desviación
        element.deviationColor = element.deviation >= 6 ? cdpReportConstants.DeviationColor.good : cdpReportConstants.DeviationColor.bad

        // redondeo de valores numéricos
        element.deviation = Util.roundDecimals(element.deviation)
        element.plantedHectares = Util.roundDecimals(element.plantedHectares)
        element.plantingWidth = element.plantingWidth != null ? Util.roundDecimals(Number(element.plantingWidth)) : null
        element.originHarvestedHectares = Util.roundDecimals(element.originHarvestedHectares)

        // UNIDADES DE MANEJO NO IDENTIFICADAS
        if (element.originDriveUnitName === cdpReportConstants.UNKNOWN_DRIVE_UNIT) {
            element.originDriveUnitName = i18n.t('cdpReport.UNKNOWN_DRIVE_UNIT')
        }
        if (element.destinationDriveUnitName === cdpReportConstants.UNKNOWN_DRIVE_UNIT) {
            element.destinationDriveUnitName = i18n.t('cdpReport.UNKNOWN_DRIVE_UNIT')
        }
    })

    return data
}

/**
 * Obtener los datos de un reporte NO DIFERIDO
 * @param {*} planterIds
 * @param {*} harvesterIds
 * @param {*} originDriveUnits
 * @param {*} destinationDriveUnits
 * @param {*} from
 * @param {*} to
 * @returns
 */
const getSummaryDirect = async (planterIds, harvesterIds, originDriveUnits, destinationDriveUnits, from, to) => {
    let processedData = null
    let reportDestinationDriveUnits = []
    let summaryReportKey = null
    try {
        const response = await cdpReportApi.getSummaryDirect({ planterIds, harvesterIds, originDriveUnits, destinationDriveUnits, from, to })
        const { data, reportKey } = response.data
        const processedSummary = processSummary(data)
        processedData = processedSummary.data
        reportDestinationDriveUnits = processedSummary.destinationDriveUnits
        summaryReportKey = reportKey
    } catch (exception) {
        console.error(exception)
    }
    return {
        data: processedData || [],
        headers: cdpReportConstants.SummaryHeaders,
        reportDestinationDriveUnits,
        summaryReportKey
    }
}

/**
 * Lanzar la generación de un reporte diferido
 * @param {*} reportQueryFilters
 * @param {*} filters
 */
const getSummaryDeferred = (reportQueryFilters, filters) => {
    cdpReportApi.getSummaryDeferred(reportQueryFilters, filters)
}

/**
 * Procesar los datos del resumen
 * @param {*} data
 * @returns
 */
const processSummary = (data) => {
    const destinationDriveUnits = []

    data.forEach((element, index) => {
        // id para la tabla
        element.id = index

        // color de la desviación
        element.deviationColor = element.deviation >= 6 ? cdpReportConstants.DeviationColor.good : cdpReportConstants.DeviationColor.bad

        // redondeo de valores numéricos
        element.deviation = Util.roundDecimals(element.deviation)
        element.plantedHectares = Util.roundDecimals(element.plantedHectares)
        element.originHarvestedHectares = Util.roundDecimals(element.originHarvestedHectares)

        // UNIDADES DE MANEJO NO IDENTIFICADAS
        if (element.originDriveUnitName === cdpReportConstants.UNKNOWN_DRIVE_UNIT) {
            element.originDriveUnitName = i18n.t('cdpReport.UNKNOWN_DRIVE_UNIT')
        }
        if (element.destinationDriveUnitName === cdpReportConstants.UNKNOWN_DRIVE_UNIT) {
            element.destinationDriveUnitName = i18n.t('cdpReport.UNKNOWN_DRIVE_UNIT')
            element.unknownDestinationDriveUnit = true
        } else {
            element.unknownDestinationDriveUnit = false
            if (!destinationDriveUnits.find(driveUnit => driveUnit.id === element.destinationDriveUnitId)) {
                destinationDriveUnits.push({
                    id: element.destinationDriveUnitId,
                    name: element.destinationDriveUnitName
                })
            }
        }
    })

    return { data, destinationDriveUnits }
}

export default {
    getDetail,
    getSummaryDirect,
    getSummaryDeferred,
    processSummary
}
